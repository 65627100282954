@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");


body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  padding: 0.5rem 0;
}

.table-alternating tr:nth-child(odd) {
   background-color:rgb(255 241 242); 
   /* background-color:#fdf3fa;  */
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid red;
  color: red;
  border-radius: 4px;
  margin: 20px 0;
}

input.error {
  border: 1px solid red;
}



label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}


